import { useStaticQuery, graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import "./menu.css"

import logo from "../images/bob_slinky_logo_white.svg"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faInstagram,
  faYoutube,
  faFacebook,
  faBandcamp,
  faSoundcloud,
  faSpotify,
  faItunes,
  faAmazon,
  faDeezer
} from '@fortawesome/free-brands-svg-icons'

const Menu = () => {
  return (
    <div className="menu-center vertical-center">
      <ul className="menu" style={{textTransform: "uppercase"}}>
          <li className="menu-logo">
              {/* <a href="#home"> */}
                  <img src={logo} alt="Bob and the apple white logo" width="100%" />
              {/* </a> */}
          </li>
          <li class="social-container">
            <a href="https://www.instagram.com/bobandtheapple"><FontAwesomeIcon icon={faInstagram} size="lg" /></a>
            <a href="https://www.facebook.com/bobandtheapple"><FontAwesomeIcon icon={faFacebook} size="lg" /></a>
            <a href="https://www.youtube.com/bobandtheappleband"><FontAwesomeIcon icon={faYoutube} size="lg" /></a>
          </li>
          <h3 class="section-head">Listen on:</h3>
          <li class="social-container">
            <a href="https://open.spotify.com/artist/6M2Pk85GotQvfbYCmWhEme?si=D18Wq_iPTUKVOiEv1Mt47w"><FontAwesomeIcon icon={faSpotify} size="lg" /></a>
            <a href="https://music.apple.com/us/artist/bob-and-the-apple/1387195969"><FontAwesomeIcon icon={faItunes} size="lg" /></a>
            <a href="https://music.amazon.com/artists/B07D67STW1/bob-and-the-apple?marketplaceId=ATVPDKIKX0DER&musicTerritory=US"><FontAwesomeIcon icon={faAmazon} size="lg" /></a>
            <a href="https://bobandtheapple.bandcamp.com"><FontAwesomeIcon icon={faBandcamp} size="lg" /></a>
            <a href="https://soundcloud.com/bobandtheapple"><FontAwesomeIcon icon={faSoundcloud} size="lg" /></a>
          </li>
          {/* <li>
              <a href="#music">Music</a>
          </li>
          <li>
              <a href="#merch">Merch</a>
          </li>
          <li>
              <a href="#videos">Videos</a>
          </li>
          <li>
              <a href="#lyrics">Lyrics</a>
          </li>
          <li>
              <a href="#about">About</a>
          </li> */}
      </ul>
    </div>
  )
}

Menu.propTypes = {
  siteTitle: PropTypes.string,
}

Menu.defaultProps = {
  siteTitle: ``,
}

export default Menu
