import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import "./slide.css"

const Slide = ({ children, id, className }) => (
  <div id={id} className={`${className ? className : ''} slide`}>
    {children}
  </div>
)

Slide.propTypes = {
  siteTitle: PropTypes.string,
}

Slide.defaultProps = {
  siteTitle: ``,
}

export default Slide
